import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logo from '../../assets/photos/logoNew3.png';

export default function Footer() {
    return (
        <footer>
            <div className="footer">
                <div className="text-left">
                <p>Tit Palace &copy; 2024</p>
                </div>
                <div className="logo-foot">
                    <a href="https://x.com/vertico_defi" className="social-media-link" aria-label="Follow us on X"
                        target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} className="icon" /> {/* Updated icon reference */}
                    </a>
                    <a href="https://www.instagram.com/titpalace/" className="social-media-link" aria-label="Follow us on Instagram"
                        target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="icon" />
                    </a>
                </div>
                {/* <div className="text">
                    <p>&nbsp;</p>
                </div> */}
            </div>
        </footer>
    )
}
