import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import '../signUp/signUp.css';
import { useAuth } from '../../contexts/AuthContext';

export default function Login({ switchToSignup }) { // Add switchToSignup prop
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth(); // Use the correct login method
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
    
        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value); // Correct login method
            // Navigate or perform additional actions if needed
        } catch (error) {
            console.error('Login error:', error); // Log the error
            setError('Failed to log in. ' + error.message); // Show detailed error message
        } finally {
            setLoading(false);
        }
    }
    
    return (
        <div>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Log In</h2>
                    {error && <Alert variant='danger'>{error}</Alert>}                  
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='form-group' id='email'>
                            <Form.Label className='form-label'>Email</Form.Label>
                            <Form.Control className='form-control' type='email' ref={emailRef} required />
                        </Form.Group>
                        <Form.Group className='form-group' id='password'>
                            <Form.Label className='form-label'>Password</Form.Label>
                            <Form.Control className='form-control' type='password' ref={passwordRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type='submit'>Log In</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className='w-100 text-center mt-2 login-text'>
                Don't have an account? <span className="switch-link" onClick={switchToSignup}>Sign Up</span> {/* Use switchToSignup */}
            </div>
        </div>
    );
}
