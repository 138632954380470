import React, { useState, useEffect } from 'react';
import { purchaseTokens } from '../../cadence/transactions/purchaseTokens';
import { getTresorDetails} from '../../cadence/scripts/getTresorDetails';
import { ToastContainer, toast } from 'react-toastify';
import * as fcl from '@blocto/fcl';

import './premiumAccess.css'

import image1 from '../../assets/photos/guess1.png';
import image2 from '../../assets/photos/guess2.png';
import image3 from '../../assets/photos/guess3.png';
import image4 from '../../assets/photos/guess4.png';

const BasicEntry = () => {
    const [answer, setAnswer] = useState(null);
    const [incorrectAnswerGiven, setIncorrectAnswerGiven] = useState(false);
    const [selectedImage, setSelectedImage] =useState(null);
    const [winningTresors, setWinningTresors] = useState([]);
    const [showCorrectResponse, setShowCorrectResponse] = useState(false);

    async function fetchTresorDetails() {
        try {
            const result = await fcl.query({
                cadence: getTresorDetails,
                args: (arg, t) => [arg("0x66b60643244a7738", t.Address)],
            });
    
            // Initialize arrays for each price category
            const winning = [];
    
            // Sort tresor IDs into their respective arrays
            result.forEach(tresor => {
                switch (tresor.amount) {
                    case "8.00000000":
                        winning.push(tresor.tresorID);
                        break;
                    default:
                        // Handle other cases or log an error
                        //console.log(`Unknown price category for Tresor ID ${tresor.tresorID}`);
                }
            });
    
            // Update state with sorted Tresor IDs
            setWinningTresors(winning);

            // Console logs for each price category
            console.log("Tresor IDs for winners: ", winning);
        } catch (error) {
            console.error("Error fetching Tresor details:", error);
        }
    }

    useEffect(() => {
        // Existing code...
        fetchTresorDetails(); // Fetch Tresor details on component mount
    }, []);

    const handleBasicWinningsCollection = async () => {
        if (winningTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        try {
            const basicTXid =await fcl.mutate({
                cadence: purchaseTokens,
                args: (arg, t) => [
                    arg(winningTresors[0], t.UInt64),
                    arg("0.0", t.UFix64),
                    arg("0x66b60643244a7738", t.Address)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
            toast.success("Transaction successful! Your winnings are on the way!.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            window.location.reload();
        } catch (error) {
            console.error("Error Processing Winnings", error);
        }
    };

    // Shuffle array function
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
    };

    // Function to get a random correct option
    const getRandomOption = (options) => {
        const shuffled = shuffleArray([...options]);
        return shuffled[0]; // Return the first option as the random correct answer
    };

    useEffect(() => {
        setAnswer(getRandomOption(conversationSteps[0].options));
        //setAnswer(conversationSteps[0].options[1]);
    })

    const handleImageClick = (selectedOption) => {
        const correctAnswer = answer
    
        if (selectedOption === correctAnswer) {
            setSelectedImage(selectedOption);
            setShowCorrectResponse(true); // Set flag to show correct response
            setSelectedImage(null);

        } else {
            setIncorrectAnswerGiven(true);
        }
    };

    const exitPalace = () => {
        window.location.reload();
    };

    const conversationSteps = [
        {
            entryDialogue: "Welcome to the opulent corridors of Tit Palace. I'm Sophia, your enigmatic guide through this realm of whispered secrets and veiled charm. In our game of intrigue, finding the hidden key could be your passage to my chamber, a place of untold stories and subtle enchantments.",
            question: "Who among these graceful presences holds the key under their chalice?",
            options: [image1, image2, image3, image4],
            correctAnswer: answer, // Ensure this is dynamically set
            get responseOnCorrect() {
                return "With a keen sense, you've unveiled the path. The key is yours, and so is the way to my chamber, where whispers turn into tales.";
            },
            get responseOnIncorrect() {
                return "Oh, how disappointing. It seems you've yet to understand the essence of our palace. Regrettably, it's time for you to depart these halls.";
            }
        }
    ]

    return (
        <div>
            {incorrectAnswerGiven ? (
                <div className="step-container">
                    <p className="step-dialogue">{conversationSteps[0].responseOnIncorrect}</p>
                    {/* Show "Exit Palace" button */}
                    <button onClick={exitPalace} className="entry-button">
                        Exit Palace
                    </button>
                </div>
            ) : (
                <div className="step-container">
                    <p className="step-dialogue">{conversationSteps[0].entryDialogue}</p>
                    <p className="step-dialogue">{conversationSteps[0].question}</p>
                    <div className="image-container">
                        {conversationSteps[0].options.map((option, index) => (
                            <img 
                                key={index} 
                                src={option} 
                                alt={`Option ${index + 1}`} 
                                onClick={() => handleImageClick(option)} // Assuming stepIndex is 0 for the first question
                                className={`option-image ${option === selectedImage ? "selected" : ""}`}
                            />
                        ))}
                    </div>
                    {showCorrectResponse && (
                        <div>
                            <p className="step-dialogue">{conversationSteps[0].responseOnCorrect}</p>
                            <button className='entry-button' onClick={handleBasicWinningsCollection}>Collect Winnings</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default BasicEntry;