import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import './signUp.css';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase'; // Import the Firestore instance
import { doc, setDoc } from "firebase/firestore"; // Import Firestore functions

export default function Signup({ switchToLogin }) {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordConfirmRef.current.value !== passwordRef.current.value) {
            return setError('Passwords do not match.');
        }

        try {
            setError('');
            setLoading(true);
            // Sign up the user
            const userCredential = await signup(emailRef.current.value, passwordRef.current.value);
            const user = userCredential.user;

            // Add the user to Firestore
            await setDoc(doc(db, 'users', user.uid), {
                email: user.email,
                createdAt: new Date().toISOString(),
                // Add any other default fields you want for the user
                profileComplete: false, // Example field
            });

            // Navigate or perform additional actions if needed
        } catch (error) {
            console.error('Signup error:', error);
            setError('Failed to create an account. ' + error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Sign Up</h2>
                    {error && <Alert variant='danger'>{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='form-group' id='email'>
                            <Form.Label className='form-label'>Email</Form.Label>
                            <Form.Control className='form-control' type='email' ref={emailRef} required />
                        </Form.Group>
                        <Form.Group className='form-group' id='password'>
                            <Form.Label className='form-label'>Password</Form.Label>
                            <Form.Control className='form-control' type='password' ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group className='form-group' id='password-confirm'>
                            <Form.Label className='form-label'>Confirm Password</Form.Label>
                            <Form.Control className='form-control' type='password' ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type='submit'>Sign Up</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className='w-100 text-center mt-2 login-text'>
                Already have an account? <span className="switch-link" onClick={switchToLogin}>Log In</span>
            </div>
        </div>
    );
}
