export const getPalaceBadgesDetails = `
import PalaceBadges from 0x66b60643244a7738
import NonFungibleToken from 0x1d7e57aa55817448

// Script to retrieve all NFTs in a user's PalaceBadges collection
pub fun main(accountAddress: Address): [PalaceBadges.palaceBadgesMetadata] {
    // Get the public capability for the PalaceBadges collection
    let collectionRef = getAccount(accountAddress)
        .getCapability<&PalaceBadges.Collection{PalaceBadges.CollectionPublic}>(PalaceBadges.CollectionPublicPath)
        .borrow()
        ?? panic("Could not borrow reference to the collection")

    // Get all NFT IDs in the user's collection
    let nftIDs = collectionRef.getIDs()
    
    // Prepare an array to hold the metadata of each NFT
    var nftDetails: [PalaceBadges.palaceBadgesMetadata] = []

    // Loop through all NFT IDs and fetch the metadata
    for id in nftIDs {
        let nftRef = collectionRef.borrowPalaceBadges(id: id)
            ?? panic("Failed to borrow reference to NFT ID")

        // Create metadata object
        let metadata = PalaceBadges.palaceBadgesMetadata(
            id: nftRef.id,
            name: nftRef.name,
            description: nftRef.description,
            image: nftRef.image,
            traits: nftRef.traits
        )

        // Append the metadata to the results array
        nftDetails.append(metadata)
    }

    // Return the array of metadata
    return nftDetails
}

`