import React, { useState, useEffect } from "react";
import './stories.css';
import { faXTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { images } from '../../assets/images'; // Import the images object

function AfternoonEncounter() {
    const [randomPageImage, setRandomPageImage] = useState(null);
    const [randomPageImages, setRandomPageImages] = useState([]);
    const [randomCourtierImage, setRandomCourtierImage] = useState(null);
    const [randomCourtierImages, setRandomCourtierImages] = useState([]);

    // Randomly select two images for pages
    useEffect(() => {
        const selectedPageImages = [];
        const pageImagesCopy = [...images.popupImages];

        for (let i = 0; i < 2; i++) {
            const randomIndex = Math.floor(Math.random() * pageImagesCopy.length);
            selectedPageImages.push(pageImagesCopy[randomIndex]);
            pageImagesCopy.splice(randomIndex, 1); // Remove selected image to avoid duplicates
        }

        setRandomPageImages(selectedPageImages);
    }, []);

    // Randomly select two images for courtiers
    useEffect(() => {
        const selectedCourtierImages = [];
        const courtierImagesCopy = [...images.courtierImages];

        for (let i = 0; i < 2; i++) {
            const randomIndex = Math.floor(Math.random() * courtierImagesCopy.length);
            selectedCourtierImages.push(courtierImagesCopy[randomIndex]);
            courtierImagesCopy.splice(randomIndex, 1); // Remove selected image to avoid duplicates
        }

        setRandomCourtierImages(selectedCourtierImages);
    }, []);

    // Randomly select one image for pages
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * images.popupImages.length);
        setRandomPageImage(images.popupImages[randomIndex]);
    }, []);

    // Randomly select one image for courtiers
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * images.courtierImages.length);
        setRandomCourtierImage(images.courtierImages[randomIndex]);
    }, []);


    return (
        <div className="story-container">
            <div className="story-content">
            <h1>Afternoon Encounter</h1>
            <div className="divider"></div>
            <div className="divider-content">
                <div className="logo-column">
                    <a href="https://x.com/vertico_defi" className="social-media-link" aria-label="Follow us on X"
                        target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} className="icon" />
                    </a>
                    <a href="https://www.instagram.com/titpalace/" className="social-media-link" aria-label="Follow us on Instagram"
                        target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="icon" />
                    </a>
                </div>
                <div className="text-column">
                    <div className="text-row">
                        <strong>Author:</strong><span className="tabbed-text">Duchess Evangeline Penrose</span>
                    </div>
                    <div className="text-row">
                        <strong>Published:</strong><span className="tabbed-text">15 Aug. 2024 - 7 min read</span>
                    </div>
                </div>
            </div>
            <div className="divider"></div>
                <p>
                    Mabel quietly chopped vegetables in the kitchen of the Tit Palace as she stole glances out of the window. She knew it was that time of day when Dame Victoria Chesterfield would take her daily walk across the balcony of her palace quarters. Suddenly, the curtains rustled under Mabel's watchful eye, and Mabel shuddered as Victoria emerged from behind them. Her golden hair perfectly complemented her light beige romper, and she confidently walked to the balcony railing, placing her hands on it with a slight arch in her back. The warm afternoon sun bathed her body, and Victoria's nipples began to soften from their initial stiffness caused by the coolness of her palace quarters. She closed her eyes and basked in the warmth.
                    <br></br><br></br>
                    Mabel paused in her cutting to admire Victoria's beauty. A part of her yearned to abandon her duties and approach Victoria just so she could run her hands over her perfectly formed body and feel the smooth skin underneath her fingertips.
                    <br></br><br></br>
                    As the sun shone upon Dame Victoria's smooth skin, Mabel's heart skipped a beat. She had always admired the Dame from afar, but today, something stirred within her that she couldn't ignore. A warmth spread through her body as she felt a strong desire for Victoria, and her thoughts quickly drifted away from her kitchen duties. Setting down the knife and wiping her hands on her apron, Mabel approached Victoria's balcony.
                    <br></br><br></br>
                    Each step seemed to quicken her pulse and make her legs tremble with anticipation. She clung onto the railings in the corridor, unable to contain the fire burning inside of her as she approached Victoria's balcony. The thought of kissing Victoria's nipples, trailing down her body to her clit, drove Mabel forward.
                    <br></br><br></br>
                    As she looked up at the balcony, the sunlight illuminated Mabel's uniform. Her gaze met Victoria's, and there was an electric connection between them. Mabel stood frozen in place as Victoria began to approach her. With each step, Victoria's beauty seemed to shine even brighter. Her fingers ran through her hair as she walked, causing her naked breasts to bounce subtly in the afternoon light.
                    <br></br><br></br>





                    By now, Mabel's desire for Victoria was almost overpowering. Her panties grew damp with lust as she stood there, waiting for Victoria to reach her.
                    <br></br><br></br>


                    Victoria gave Mabel a subtle gesture, signaling for her to come over to the balcony. Without hesitation, Mabel mustered all her remaining strength. She climbed onto the corridor ledge, reaching out towards the railing of Victoria's balcony. Mabel had to carefully navigate a small gap. Still, luckily, there was an opening underneath the banister for her legs, and she grabbed onto the top of the railing with her arms. Victoria offered her hand to Mabel, who almost slipped while trying to accept it. With a firm pull from Victoria, Mabel swung her leg over the banister and landed on the other side with a small jump. Now, she stood before the woman she had always admired, Victoria.
                    <br></br><br></br>

                    {randomPageImage && (
                        <div className="image-row-new">
                            <div className="buy-now-text">BUY NOW</div>
                            {randomPageImages.map((image, index) => (
                                <div key={index} className="image-tile-new">
                                    <a href={getStripeLink(image.price)} target="_blank" rel="noopener noreferrer">
                                        <img src={image.src} alt={image.alt} />
                                    </a>
                                    <div className="price-tag-new">$ {image.price}</div>
                                    <h5>{image.alt}</h5>
                                    <p>Tits: {image.tits}</p>
                                </div>
                            ))}
                        </div>
                    )}

                    As she stood on the balcony, the Dame turned to look at her with a seductive expression. Mabel's words caught in her throat momentarily before she finally spoke.
                    <br></br><br></br>
                    "Dame Victoria, forgive me for my forwardness, but I couldn't resist coming to you... you are the most exquisite beauty I have ever seen," Mabel said, her voice barely above a whisper.
                    <br></br><br></br>
                    Victoria's eyes widened in surprise, and a faint blush painted her cheeks. But instead of turning away or scolding Mabel, she ran her fingers through Mabel's hair, looking admirably into her eyes.
                    "Mabel, my dear chef, you have a way with words that is as delightful as your cooking," Victoria said softly, her touch sending shivers down Mabel's spine. "I have noticed your adoring gaze from the kitchen window, and I must confess that it warms my heart to know that my charms have not gone unnoticed by you."
                    <br></br><br></br>
                    Mabel's cheeks flushed at Victoria's words, her heart pounding with excitement and nervousness. She couldn't believe her bold admission had been met with warmth and understanding from the esteemed Dame.
                    <br></br><br></br>
                    Victoria's fingers trailed down Mabel's arm, leaving a tingling sensation in their wake. "If it pleases you, I would be honored to have you join me for a stroll in the palace gardens," Victoria suggested with a smile that made Mabel's heart soar.
                    <br></br><br></br>


                    Mabel entertained the idea, but her long-burning passion could no longer be contained. Without warning, she grabbed Victoria from behind and pressed their bodies together. "Why wait any longer, Victoria?" Victoria let out a pleasurable moan as she followed Mabel's lead, and they disappeared behind the curtains on the balcony into Victoria's private quarters. The gentle rustling of the wind outside accompanied the soft thud of their bodies as they landed gracefully on Victoria's luxurious bed.
                    <br></br><br></br>



                    They had been holding back their desires for months, but now, with the warm afternoon sun setting the mood, they couldn't resist any longer. Victoria's hands reached around to grip Mabel's waist, pulling her closer to their bodies. Their lips met in a fervent kiss, tasting each other's desires and passions.
                    <br></br><br></br>
                    Mabel's hands roamed Dame Victoria's body, feeling the curve of her breasts and the warmth of her skin. Victoria's fingers teased and caressed Mabel's neck, her nails gently scraping against her skin. The scent of their skin mingled with the aroma of Mabel's cooking, creating a sensual ambiance that drove them to a state of unbridled desire.
                    <br></br><br></br>

                    {randomCourtierImage && (
                        <div className="image-row-new">
                            <div className="buy-now-text">BUY NOW</div>
                            {randomCourtierImages.map((image, index) => (
                                <div key={index} className="image-tile-new">
                                    <a href={getStripeLink(image.price)} target="_blank" rel="noopener noreferrer">
                                        <img src={image.src} alt={image.alt} />
                                    </a>
                                    <div className="price-tag-new">$ {image.price}</div>
                                    <h5>{image.alt}</h5>
                                    <p>Tits: {image.tits}</p>
                                </div>
                            ))}
                        </div>
                    )}
                    Their tongues intertwined in a passionate dance, igniting a fire within them that spread to their souls. They couldn't keep their hands off each other, and soon, their clothes were discarded as they explored every inch of each other's exposed skin with their lips. The heat between their legs grew into an unstoppable desire, only to be satisfied by the sweet release of pleasure from one another.
                    <br></br><br></br>
                    Mabel's hands explored Victoria's body, eventually finding her clit and eliciting a soft moan from her. She moved her fingers in circles, causing Victoria to arch her back with each rotation. Unable to resist any longer, Mabel moistened two of her fingers with saliva before delicately sliding them between Victoria's open legs. Mabel's fingers traced the curves and folds of Victoria's most intimate area, their movements becoming faster and more intense as Victoria's pleasure grew until she was swaying with pure bliss.
                    <br></br><br></br>
                    Mabel reveled in the feeling of plunging her fingers deeply into Victoria's wet and eager pussy. With her other hand, she spread apart Victoria's labia to reveal her swollen clitoris, ready for Mabel's skilled tongue to eagerly fulfill Victoria's desires. Mabel started out slowly, savoring each lick before picking up the pace as Victoria's legs spread open in response. As Mabel thrust her head forward again and again between Victoria's legs, she could feel her own breasts sliding against the smooth silk sheets, adding to the pleasure she was experiencing from finally being with Victoria. The faster Mabel's tongue made its way across Victoria's clit, the more fervently she penetrated Victoria with her fingers. But soon, two fingers were no longer enough to satisfy Mabel's arousal. She pulled them out and examined them in the sunlight, catching an intoxicating wisp of their musky scent. Fueled by desire, Mabel continued to lick while using all four of her fingers to penetrate Victoria deeply and rhythmically. Mabel intensified her licking, aware that Victoria was on the brink of orgasm; Mabel could sense the powerful climax building up within Victoria's spread legs. And then it happened - Victoria's body convulsed and tensed as she succumbed to pure ecstasy.
                    <br></br><br></br>

                    After a moment of stillness overcoming her from the intense pleasure, Victoria reached into the bedside table drawer and retrieved a sizeable golden dildo. Mabel watched with wide eyes as Victoria took hold of the ribbed toy and inserted it into herself while passionately kissing Mabel. Using her other hand, she pulled back on Mabel's jaw and removed the cream-covered dildo from deep within herself before shoving it down Mabel's throat. By now, Mabel was on the verge of orgasm just from being a part of the scene that was unfolding in her midst. Victoria tightened her grip on Mabel's jaw and threw her naked body back onto the bed. Mabel opened her legs as wide as she could, eagerly awaiting what would come next. Victoria leaned forward with a look of pure desire in her eyes, the dildo gripped tightly in her hand, ready to penetrate Mabel's surging pussy.
                    <br></br><br></br>
                    The smell of burnt onions rose from beyond the curtains into Dame Victoria's private quarters. Mabel let out an uncontrollable moan in anticipation of the pleasure to come. The burning odor from beyond the curtains became more vigorous; Mabel's moaning grew louder. The snap of grease, the soft poof of a flame's beginning, a loud knock at the door. Mabel let out a scream as Victoria shoved the wet dildo deep inside her.
                    <br></br><br></br>
                    "Mabel?!" a voice hollered from beyond the door. 
                    <br></br><br></br>
                    To be continued...
                </p>
            </div>
        </div>
    )
}

function getStripeLink(price) {
    switch (price) {
        case "8":
            return "https://buy.stripe.com/dR67tFcy9d6SeHu5ko";
        case "12":
            return "https://buy.stripe.com/eVadS355H6IugPCdQW";
        case "10":
            return "https://buy.stripe.com/28oaFR41D2se9naaEJ";
        case "18":
            return "https://buy.stripe.com/5kAbJV7dP5Eq7f2fYY";
        case "20":
            return "https://buy.stripe.com/28oaFRgOpeaW0QEfYZ";
        case "23":
            return "https://buy.stripe.com/eVa5lx9lXgj41UI146";
        case "25":
            return "https://buy.stripe.com/28o01dbu53wieHu8wz";
        default:
            return "#"; // Default link or an empty link
    }
}



export default AfternoonEncounter;