import React, { useState, useEffect } from 'react';
import { purchaseTokens } from '../../cadence/transactions/purchaseTokens';
import { getTresorDetails} from '../../cadence/scripts/getTresorDetails';
import { ToastContainer, toast } from 'react-toastify';
import * as fcl from '@blocto/fcl';

import './premiumAccess.css'

import imageOne from '../../assets/photos/velvetLounge.png';
import imageTwo from '../../assets/photos/saphirePool.png';
import imageThree from '../../assets/photos/roseGarden.png';
import imageFour from '../../assets/photos/gallery.png';
import imageFive from '../../assets/photos/moonlitBalcony.png';
import imageSix from '../../assets/photos/photoGallery.png';
import imageSeven from '../../assets/photos/candleLight.png';
import imageEight from '../../assets/photos/goldenBallroom.png';


const PremiumAccess = () => {
    const [answerOne, setAnswerOne] = useState(null);
    const [answerTwo, setAnswerTwo] = useState(null);
    const [answerThree, setAnswerThree] = useState(null);
    const [answerFour, setAnswerFour] = useState(null);
    const [currentStepIndex, setCurrentStepIndex] = useState(0); // Initialize with 0
    const [incorrectAnswerGiven, setIncorrectAnswerGiven] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // Add this line
    const [showCorrectResponse, setShowCorrectResponse] = useState(false);
    const [finalStepCorrect, setFinalStepCorrect] = useState(false);
    const [winningTresors, setWinningTresors] = useState([]);


    async function fetchTresorDetails() {
        try {
            const result = await fcl.query({
                cadence: getTresorDetails,
                args: (arg, t) => [arg("0x66b60643244a7738", t.Address)],
            });
    
            // Initialize arrays for each price category
            const winning = [];
    
            // Sort tresor IDs into their respective arrays
            result.forEach(tresor => {
                switch (tresor.amount) {
                    case "88.00000000":
                        winning.push(tresor.tresorID);
                        break;
                    default:
                        // Handle other cases or log an error
                        //console.log(`Unknown price category for Tresor ID ${tresor.tresorID}`);
                }
            });
    
            // Update state with sorted Tresor IDs
            setWinningTresors(winning);

            // Console logs for each price category
            console.log("Tresor IDs for winners: ", winning);
        } catch (error) {
            console.error("Error fetching Tresor details:", error);
        }
    }

    useEffect(() => {
        // Existing code...
        fetchTresorDetails(); // Fetch Tresor details on component mount
    }, []);



    const handlePremiumWinningsCollection = async () => {
        if (winningTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        try {
            const basicTXid =await fcl.mutate({
                cadence: purchaseTokens,
                args: (arg, t) => [
                    arg(winningTresors[0], t.UInt64),
                    arg("0.0", t.UFix64),
                    arg("0x66b60643244a7738", t.Address)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
            toast.success("Transaction successful! Your winnings are on the way!.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            window.location.reload();
        } catch (error) {
            console.error("Error Processing Winnings", error);
        }
    };

    

    // Shuffle array function
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
    };

    // Function to get a random correct option
    const getRandomOption = (options) => {
        const shuffled = shuffleArray([...options]);
        return shuffled[0]; // Return the first option as the random correct answer
    };

    const exitPalace = () => {
        window.location.reload();
    };

    

    // Initialize correct answers in useEffect
    useEffect(() => {
        setAnswerOne(getRandomOption(conversationSteps[0].options));
        // setAnswerOne(conversationSteps[0].options[1]);
        setAnswerTwo(getRandomOption(conversationSteps[1].options));
        // setAnswerTwo(conversationSteps[1].options[1]);
        setAnswerThree(getRandomOption(conversationSteps[2].options));
        // setAnswerThree(conversationSteps[2].options[1]);
        setAnswerFour(getRandomOption(conversationSteps[3].options));
        // setAnswerFour(conversationSteps[3].options[1]);
        // ... Add similar lines for other steps
    }, []);

    const conversationSteps = [
        {
            entryDialogue: "Welcome to Tit Palace! I'm Isabella, your sultry guide. Only those who understand the essence of the palace will reach my private chambers. Are you ready for this exclusive journey?",
            question: "Where would you find me on a sultry evening?",
            options: [imageOne, imageTwo],
            correctAnswer: answerOne,
            get dialogue() {
                return "Relaxing in the plush Velvet Lounge or swimming under the moonlight in the Sapphire Pool?";
            },
            get responseOnCorrect() {
                return "You know me well! Let’s move deeper into the palace.";
            },
            get responseOnIncorrect() {
                return "Oh, I thought you knew me better. The palace isn’t for everyone, perhaps it's best if you leave.";
            }
        },
        {
            question: "Can you guess where I spend my afternoons?",
            options: [imageThree, imageFour],
            correctAnswer: answerTwo,
            get dialogue() {
                return "Amidst the fragrant roses in the Garden or listening to secrets in the Whispering Gallery?";
            },
            get responseOnCorrect() {
                return "Impressive! You’re getting closer to my chambers.";
            },
            get responseOnIncorrect() {
                return "Disappointing... it seems the palace’s charm is lost on you. I must ask you to depart.";
            }
        },
        {
            question: "Where do I gaze at the stars?",
            options: [imageFive, imageSix],
            correctAnswer: answerThree,
            get dialogue() {
                return "From the Moonlit Balcony or amongst the timeless beauty of the Hall of Portraits?";
            },
            get responseOnCorrect() {
                return "You're intriguing... Let’s continue our journey.";
            },
            get responseOnIncorrect() {
                return "It seems you don't quite grasp the palace's allure. I think it’s time for you to leave.";
            }
        },
        {
            question: "Where will you find the heart of my world?",
            options: [imageSeven, imageEight],
            correctAnswer: answerFour,
            get dialogue() {
                return "In the intimate Candlelit Boudoir or the grand Golden Ballroom?";
            },
            get responseOnCorrect() {
                return "You’ve made it to my chambers! A rare privilege, indeed.";
            },
            get responseOnIncorrect() {
                return "Alas, you've missed the essence of Tit Palace. Please, make your way out.";
            }
        }
    ];

    const renderCorrectResponse = () => {
        return (
            <div className="step-container"> {/* Centers the content */}
                <p className="step-dialogue">{conversationSteps[currentStepIndex - 1].responseOnCorrect}</p>
            </div>
        );
    };
    

    // Additional logic and functions for managing game state, user interactions, and rendering components.



    const handleImageClick = (selectedOption, stepIndex) => {
        const correctAnswer = [answerOne, answerTwo, answerThree, answerFour][stepIndex];
    
        if (selectedOption === correctAnswer) {
            setSelectedImage(selectedOption);
            setShowCorrectResponse(true); // Set flag to show correct response
            setCurrentStepIndex(currentStepIndex + 1); // Immediately move to the next step
            setSelectedImage(null);

            if (stepIndex === conversationSteps.length - 1) {
                // If it's the final step, set finalStepCorrect to true
                setFinalStepCorrect(true);
            }
        } else {
            setIncorrectAnswerGiven(true);
        }
    };
    

    // Render method for each step
    const renderStep = (stepIndex) => {
        const step = conversationSteps[stepIndex];
    
        return (
            <div className="step-container">
                {stepIndex === 0 && <p className="step-dialogue">{step.entryDialogue}</p>}
                <p className="step-dialogue">{step.question}</p>
                <p className="step-dialogue">{step.dialogue}</p>
                <div className="image-container">
                    {step.options.map((option, index) => (
                        <img 
                            key={index} 
                            src={option} 
                            alt={`Option ${index + 1}`} 
                            onClick={() => handleImageClick(option, stepIndex)}
                            className={`option-image ${option === step.correctAnswer && currentStepIndex > stepIndex ? "correct" : ""}`}
                        />
                    ))}
                </div>
            </div>
        );
    };

    // Render the current step based on state
    return (
        <div>
            {incorrectAnswerGiven ? (
                <div className="step-container">
                    <p className="step-dialogue">{conversationSteps[currentStepIndex].responseOnIncorrect}</p>
                    {/* Show "Exit Palace" button */}
                    <button onClick={exitPalace} className="entry-button">
                        Exit Palace
                    </button>
                </div>
            ) : (
                [...Array(currentStepIndex)].map((_, index) => (
                    <div key={index}>
                        {renderStep(index)}
                        {showCorrectResponse && index === currentStepIndex - 1 && renderCorrectResponse()}
                    </div>
                ))
            )}
            {!incorrectAnswerGiven && currentStepIndex < conversationSteps.length && renderStep(currentStepIndex)}

            {/* Show "Collect winning" button if final step is correct */}
            {finalStepCorrect && (
                <div className='step-container'>
                    <button onClick={handlePremiumWinningsCollection} className="entry-button">
                        Collect 88 $TITs 
                    </button>
                </div>
            )}
        </div>
    );
    
    

}

export default PremiumAccess;