export const getPortraitListingDetails = `

import PortraitsStorefront from 0x66b60643244a7738

pub struct ListingData {
    pub let listingId: UInt64
    pub let nftIDs: [UInt64] // Array of NFT IDs
    pub let price: UFix64
    pub let purchased: Bool
    pub let storefrontAddress: Address

    init(listingId: UInt64, nftIDs: [UInt64], price: UFix64, purchased: Bool, storefrontAddress: Address) {
        self.listingId = listingId
        self.nftIDs = nftIDs
        self.price = price
        self.purchased = purchased
        self.storefrontAddress = storefrontAddress
    }
}

pub fun main(accountAddress: Address): [ListingData] {
    let storefrontRef = getAccount(accountAddress)
        .getCapability<&PortraitsStorefront.Storefront{PortraitsStorefront.StorefrontPublic}>(PortraitsStorefront.StorefrontMainPublicPath)
        .borrow()
        ?? panic("Could not borrow a reference to the storefront")

    let listingIDs = storefrontRef.getListingIDs()
    var listingsData: [ListingData] = []

    for id in listingIDs {
        let listingRef = storefrontRef.borrowListing(listingResourceID: id)
            ?? panic("Could not borrow a reference to the listing")
        let details = listingRef.getDetails()
        let listingData = ListingData(
            listingId: id, 
            nftIDs: details.nftIDs, // Retrieving NFT IDs from the listing details
            price: details.salePrice, 
            purchased: details.purchased, 
            storefrontAddress: accountAddress
        )
        listingsData.append(listingData)
    }

    return listingsData
}


`