export const getPalaceBadgesIDs = `
import PalaceBadges from 0x66b60643244a7738
import NonFungibleToken from 0x1d7e57aa55817448

pub fun main(address: Address): [UInt64] {
    let account = getAccount(address)
    let collectionRef = account.getCapability<&PalaceBadges.Collection{NonFungibleToken.CollectionPublic}>(PalaceBadges.CollectionPublicPath)
    .borrow()
        ?? panic("Could not borrow reference to the collection")

    return collectionRef.getIDs()
}


`