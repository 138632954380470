export const purchasePortraitListing = `

// Mainnet
import NonFungibleToken from 0x1d7e57aa55817448
import TitPalacePortraits from 0x66b60643244a7738
import FungibleToken from 0xf233dcee88fe0abe
import TitToken from 0x66b60643244a7738
import PortraitsStorefront from 0x66b60643244a7738




transaction(listingResourceID: UInt64, storefrontAddress: Address) {
    let paymentVault: @FungibleToken.Vault
    let nftCollection: &NonFungibleToken.Collection{NonFungibleToken.Receiver}
    let storefront: &PortraitsStorefront.Storefront{PortraitsStorefront.StorefrontPublic}
    let listing: &PortraitsStorefront.Listing{PortraitsStorefront.ListingPublic}
    let salePrice: UFix64
    let balanceBeforeTransfer: UFix64
    let titTokenVault: &TitToken.Vault

    prepare(buyer: AuthAccount) {
                // Initialize the buyer's collection if they do not already have one
        if buyer.borrow<&TitPalacePortraits.Collection>(from: TitPalacePortraits.CollectionStoragePath) == nil {
            let collection <- TitPalacePortraits.createEmptyCollection() as! @TitPalacePortraits.Collection
            buyer.save(<-collection, to: TitPalacePortraits.CollectionStoragePath)
            
            buyer.link<&TitPalacePortraits.Collection{NonFungibleToken.CollectionPublic, TitPalacePortraits.CollectionPublic}>(
                TitPalacePortraits.CollectionPublicPath,
                target: TitPalacePortraits.CollectionStoragePath
            )
             ?? panic("Could not link collection Pub Path");
        }

                // Get the storefront reference from the seller
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&PortraitsStorefront.Storefront{PortraitsStorefront.StorefrontPublic}>(
                PortraitsStorefront.StorefrontMainPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

                    // Get the listing by ID from the storefront
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        self.salePrice = self.listing.getDetails().salePrice

                // Get a DUC vault from Dapper's account
        self.titTokenVault = buyer.borrow<&TitToken.Vault>(from: /storage/TitTokenVault)
            ?? panic("Cannot borrow TitToken vault from account storage")
        self.balanceBeforeTransfer = self.titTokenVault.balance
        self.paymentVault <- self.titTokenVault.withdraw(amount: self.salePrice)

                // Get the collection from the buyer so the NFT can be deposited into it
        self.nftCollection = buyer.borrow<&NonFungibleToken.Collection{NonFungibleToken.Receiver}>(
            from: TitPalacePortraits.CollectionStoragePath
        ) ?? panic("Cannot borrow NFT collection receiver from account")



    }

    execute {
        self.listing.purchase(
            payment: <- self.paymentVault,
            collection: self.nftCollection
        )

    }
}


`