import React from 'react';
import './popUp.css';
import ImageCarousel from './imageCarousel'; 

function AgeVerificationPopup({ isProcessing, onCancel, onVerifyAge, onSignIn, userLoggedIn }) {
    console.log("User Logged In Status in Popup:", userLoggedIn); // Log the passed prop

    return (
        <div className="age-verification-popup">
            <div className="popup-content">
                <h2>Verify Age</h2>
                <ImageCarousel />
                <p>You must be 18 years or older to access this content.</p>
                <div className="button-container">
                    {isProcessing ? (
                        <p>Processing...</p>
                    ) : userLoggedIn ? (
                        <>
                            <button className="button-ctap" onClick={onVerifyAge} disabled={isProcessing}>
                                Verify Age
                            </button>
                            <button className="button-ctap" onClick={onCancel} disabled={isProcessing}>
                                Cancel
                            </button>
                        </>
                    ) : (
                        <button className="button-ctap-new" onClick={onSignIn}>
                            Sign In
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}


export default AgeVerificationPopup;
