import React, { useState, useEffect } from "react";
import { transferFlow } from '../../cadence/transferFlow';
import { purchaseTokens } from '../../cadence/transactions/purchaseTokens';
import { purchaseTokensWithDust } from '../../cadence/transactions/purchaseTokensWithDust';
import { getTresorDetails} from '../../cadence/scripts/getTresorDetails';
import { getTitBalance } from '../../cadence/scripts/getTitBalance';
import { checkFlowBalance } from '../../cadence/scripts/checkFlowBalance';
import { getDustBalance } from '../../cadence/scripts/getDustBalance';
import * as fcl from '@blocto/fcl';
import './shop.css'
import { ToastContainer, toast } from 'react-toastify';
import thirty from '../../assets/photos/coinBag11.png';
import eighty from '../../assets/photos/coinBag10.png';
import seventeen from '../../assets/photos/coinBag9.png';
import thirtysix from '../../assets/photos/coinBag8.png';
import ninetyfive from '../../assets/photos/coinBag7.png';
import twenty from '../../assets/photos/coinBag6.png';
import NFTDisplay from './porttraitShop';
import { initTit } from '../../cadence/initTit';

function Shop() {
    // const [amountTit, setAmountTit] = useState("");
    const [user, setUser] = useState({ loggedIn: null });  
    //const [amountFlow, setAmountFlow] = useState("");
    const [userAddress, setUserAddress] = useState(null);
    const [sixPriceTresors, setSixPriceTresors] = useState([]);
    const [fifteenPriceTresors, setFifteenPriceTresors] = useState([]);
    const [thirtyPriceTresors, setThirtyPriceTresors] = useState([]);
    const [sixtyPriceTresors, setSixtyPriceTresors] = useState([]);
    const [oneFiftyPriceTresors, setOneFiftyPriceTresors] = useState([]);
    const [threeHundredPriceTresors, setThreeHundredPriceTresors] = useState([]);
    const [titBalance, setTitBalance] = useState(null);
    const [flowBalance, setFlowBalance] = useState(null);
    const [dustBalance, setDustBalance] = useState(null);
    const [currency, setCurrency] = useState('flow');

    const handleCurrencyChange = (newCurrency) => {
        setCurrency(newCurrency);
    };
    

    
    useEffect(() => {
        fcl.currentUser.subscribe((currentUser) => {
            setUser(currentUser);
            setUserAddress(currentUser.addr);
        });
    }, []); // 
    async function fetchTresorDetails() {
        try {
            const result = await fcl.query({
                cadence: getTresorDetails,
                args: (arg, t) => [arg("0x66b60643244a7738", t.Address)],
            });
    
            // Initialize arrays for each price category
            const six = [], fifteen = [], thirty = [], sixty = [], oneFifty = [], threeHundred = [];
    
            // Sort tresor IDs into their respective arrays
            result.forEach(tresor => {
                switch (tresor.price) {
                    case "6.00000000":
                        six.push(tresor.tresorID);
                        break;
                    case "15.00000000":
                        fifteen.push(tresor.tresorID);
                        break;
                    case "30.00000000":
                        thirty.push(tresor.tresorID);
                        break;
                    case "60.00000000":
                        sixty.push(tresor.tresorID);
                        break;
                    case "150.00000000":
                        oneFifty.push(tresor.tresorID);
                        break;
                    case "300.00000000":
                        threeHundred.push(tresor.tresorID);
                        break;
                    default:
                        // Handle other cases or log an error
                        console.log(`Unknown price category for Tresor ID ${tresor.tresorID}`);
                }
            });
    
            // Update state with sorted Tresor IDs
            setSixPriceTresors(six);
            setFifteenPriceTresors(fifteen);
            setThirtyPriceTresors(thirty);
            setSixtyPriceTresors(sixty);
            setOneFiftyPriceTresors(oneFifty);
            setThreeHundredPriceTresors(threeHundred);

            // Console logs for each price category
            console.log("Tresor IDs for 6: ", six);
            console.log("Tresor IDs for 15: ", fifteen);
            console.log("Tresor IDs for 30: ", thirty);
            console.log("Tresor IDs for 60: ", sixty);
            console.log("Tresor IDs for 150: ", oneFifty);
            console.log("Tresor IDs for 300: ", threeHundred);
        } catch (error) {
            console.error("Error fetching Tresor details:", error);
        }
    }
    
    useEffect(() => {
        // Existing code...
        fetchTresorDetails(); // Fetch Tresor details on component mount
    }, []);

    async function fetchTitBalance() {
        try {
            const balance = await fcl.query({
                cadence: getTitBalance,
                args: (arg, t) => [arg(userAddress, t.Address)],
            });
            const formattedBalance = Number(balance).toFixed(2);

            setTitBalance(formattedBalance); // Update the balance state with the formatted value
            console.log("TIT Balance:", formattedBalance); //
        } catch (error) {
            console.error("Error fetching TIT balance:", error);
        }
    }

    async function fetchDustBalance() {
        try {
            const balance = await fcl.query({
                cadence: getDustBalance,
                args: (arg, t) => [arg(userAddress, t.Address)],
            });
            const formattedBalance = Number(balance).toFixed(2);

            setDustBalance(formattedBalance); // Update the balance state with the formatted value
            console.log("Dust Balance:", formattedBalance); //
        } catch (error) {
            console.error("Error fetching Dust balance:", error);
        }
    }

    async function fetchFlowBalance() {
        try {
            const balance = await fcl.query({
                cadence: checkFlowBalance,
                args: (arg, t) => [arg(userAddress, t.Address)],
            });
            const formattedBalance = Number(balance).toFixed(2);

            setFlowBalance(formattedBalance); // Update the balance state with the formatted value
            console.log("Flow Balance:", formattedBalance); //
        } catch (error) {
            console.error("Error fetching TIT balance:", error);
        }
    }

    useEffect(() => {
        // Fetch all balances on component mount
        fetchTitBalance();
        fetchFlowBalance();
        fetchDustBalance();
    }, [userAddress]);
    

    const transferFlow6 = async () => {
        //setAmountFlow("0.006");
        if (sixPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokens,
            args: (arg, t) => [
                arg(sixPriceTresors[0], t.UInt64),
                arg("6.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferDust6 = async () => {
        //setAmountFlow("0.006");
        if (sixPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokensWithDust,
            args: (arg, t) => [
                arg(sixPriceTresors[0], t.UInt64),
                arg("950.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }


    const transferFlow15 = async () => {
        //setAmountFlow("0.006");

        if (fifteenPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokens,
            args: (arg, t) => [
                arg(fifteenPriceTresors[0], t.UInt64),
                arg("15.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferDust15 = async () => {
        //setAmountFlow("0.006");

        if (fifteenPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokensWithDust,
            args: (arg, t) => [
                arg(fifteenPriceTresors[0], t.UInt64),
                arg("2350.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }


    const transferFlow30 = async () => {
        //setAmountFlow("0.006");
        if (thirtyPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokens,
            args: (arg, t) => [
                arg(thirtyPriceTresors[0], t.UInt64),
                arg("30.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferDust30 = async () => {
        //setAmountFlow("0.006");
        if (thirtyPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokensWithDust,
            args: (arg, t) => [
                arg(thirtyPriceTresors[0], t.UInt64),
                arg("4700.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferFlow60 = async () => {
        //setAmountFlow("0.006");
        if (sixtyPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokens,
            args: (arg, t) => [
                arg(sixtyPriceTresors[0], t.UInt64),
                arg("60.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferDust60 = async () => {
        //setAmountFlow("0.006");
        if (sixtyPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokensWithDust,
            args: (arg, t) => [
                arg(sixtyPriceTresors[0], t.UInt64),
                arg("9400.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferFlow150 = async () => {
        //setAmountFlow("0.006");
        if (oneFiftyPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokens,
            args: (arg, t) => [
                arg(oneFiftyPriceTresors[0], t.UInt64),
                arg("150.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferDust150 = async () => {
        //setAmountFlow("0.006");
        if (oneFiftyPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokensWithDust,
            args: (arg, t) => [
                arg(oneFiftyPriceTresors[0], t.UInt64),
                arg("23600.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferFlow300 = async () => {
        //setAmountFlow("0.006");
        if (threeHundredPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokens,
            args: (arg, t) => [
                arg(threeHundredPriceTresors[0], t.UInt64),
                arg("300.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const transferDust300 = async () => {
        //setAmountFlow("0.006");
        if (threeHundredPriceTresors.length === 0) {
            console.error("No Tresors available for this price category");
            return;
        }
        const txid = await fcl.mutate({
            cadence: purchaseTokensWithDust,
            args: (arg, t) => [
                arg(threeHundredPriceTresors[0], t.UInt64),
                arg("48000.0", t.UFix64),
                arg("0x66b60643244a7738", t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
    }

    const initTitToken = async () => {
        try {
            // Start the mutation
            const transactionId = await fcl.mutate({
                cadence: initTit,
                args: (arg, t) => [], // if your initTit requires arguments, they go here
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
            
            // Await the transaction to be sealed
            const transaction = await fcl.tx(transactionId).onceSealed();
            console.log(transaction); // log the transaction status and events
            
            // You can also check the status of the transaction if needed
            if (transaction.status === 4) {
                toast.success("Successfully initialized TIT Token!");
            } else {
                toast.error("Transaction failed: " + transaction.errorMessage);
            }
        } catch (error) {
            console.error("Transaction failed", error);
            toast.error("Transaction failed: " + error.message);
        }
    };


    return(
        <div>
            <h1 className="shop-header">Initialize Wallet</h1>
            <div className="init-button">
               <button onClick={initTitToken} className="button-top">INIT</button>
            </div>
            <div class="section-divider"></div>
            <h1 className="shop-header">BUY TIT$</h1>
            <div className="currency-options">
                <button 
                    className={`button ${currency === 'flow' ? 'selected' : ''}`}
                    onClick={() => handleCurrencyChange('flow')}
                >
                    $FLOW
                </button>
                <button 
                    className={`button ${currency === 'dust' ? 'selected' : ''}`}
                    onClick={() => handleCurrencyChange('dust')}
                >
                    $DUST
                </button>
            </div>
            {currency === 'flow' ? (
                <div>

                    <div class="section-divider"></div>

                    <h2 className="shop-subheader">BALANCES</h2>
                    <div className="currency-options2">
                        <div className="balance-tile">
                            <p>$TIT BALANCE <br></br> {titBalance}</p>
                        </div>
                        <div className="balance-tile">
                            <p>$FLOW BALANCE <br></br> {flowBalance}</p>
                        </div>
                    </div>
                    <div class="section-divider"></div>
                    <div className="shop-page">
                        <div className="buy">
                            <img src={thirty} className="coin-bag" alt="small bag of tokens"></img>
                            <p>6 $FLOW → 30 $TIT</p>
                            <button 
                                onClick={transferFlow6} 
                                className="button"
                                disabled={parseFloat(flowBalance) < 6} // Disable button if flowBalance is less than 6
                            >
                                {parseFloat(flowBalance) < 6 ? "ADD FUNDS" : "BUY"} 
                            </button>
                        </div>
                        <div className="buy">
                            <img src={eighty} className="coin-bag" alt="small bag of tokens"></img>
                            <p>15 $FLOW → 80 $TIT</p>
                            <button 
                                onClick={transferFlow15} 
                                className="button"
                                disabled={parseFloat(flowBalance) < 15} // Disable button if flowBalance is less than 6
                            >
                                {parseFloat(flowBalance) < 15 ? "ADD FUNDS" : "BUY"} 
                            </button>
                        </div>
                        <div className="buy">
                            <img src={seventeen} className="coin-bag" alt="small bag of tokens"></img>
                            <p>30 $FLOW → 170 $TIT</p>
                            <button 
                                onClick={transferFlow30} 
                                className="button"
                                disabled={parseFloat(flowBalance) < 30} // Disable button if flowBalance is less than 6
                            >
                                {parseFloat(flowBalance) < 30 ? "ADD FUNDS" : "BUY"} 
                            </button>
                        </div>
                        <div className="buy">
                            <img src={thirtysix} className="coin-bag" alt="small bag of tokens"></img>
                            <p>60 $FLOW → 360 $TIT</p>
                            <button 
                                onClick={transferFlow60} 
                                className="button"
                                disabled={parseFloat(flowBalance) < 60} // Disable button if flowBalance is less than 6
                            >
                                {parseFloat(flowBalance) < 60 ? "ADD FUNDS" : "BUY"} 
                            </button>
                        </div>
                        <div className="buy">
                            <img src={ninetyfive} className="coin-bag" alt="small bag of tokens"></img>
                            <p>150 $FLOW → 950 $TIT</p>
                            <button 
                                onClick={transferFlow150} 
                                className="button"
                                disabled={parseFloat(flowBalance) < 150} // Disable button if flowBalance is less than 6
                            >
                                {parseFloat(flowBalance) < 150 ? "ADD FUNDS" : "BUY"} 
                            </button>
                        </div>
                        <div className="buy">
                            <img src={twenty} className="coin-bag" alt="small bag of tokens"></img>
                            <p>300 $FLOW → 2000 $TIT</p>
                            <button 
                                onClick={transferFlow300} 
                                className="button"
                                disabled={parseFloat(flowBalance) < 300} // Disable button if flowBalance is less than 6
                            >
                                {parseFloat(flowBalance) < 300 ? "ADD FUNDS" : "BUY"} 
                            </button>
                        </div>
                    </div>

                </div>
            ) : (
                <div>

                    <div class="section-divider"></div>

                    <h2 className="shop-subheader">BALANCES</h2>
                    <div className="currency-options2">
                        <div className="balance-tile">
                            <p>$TIT BALANCE <br></br> {titBalance}</p>
                        </div>
                        <div className="balance-tile">
                            <p>$DUST BALANCE <br></br> {dustBalance}</p>
                        </div>
                    </div>

                    <div class="section-divider"></div>

                    <div className="shop-page">
                        <div className="buy">
                            <img src={thirty} className="coin-bag" alt="small bag of tokens"></img>
                            <p>950 $DUST → 30 $TIT</p>
                            <button 
                                onClick={transferDust6} 
                                className="button"
                                disabled={parseFloat(dustBalance) < 950} // Disable button if dustBalance is less than 950
                            >
                                {parseFloat(dustBalance) < 950 ? "ADD FUNDS" : "BUY"}
                            </button>
                        </div>
                        <div className="buy">
                            <img src={eighty} className="coin-bag" alt="small bag of tokens"></img>
                            <p>2350 $DUST → 80 $TIT</p>
                            <button 
                                onClick={transferDust15} 
                                className="button"
                                disabled={parseFloat(dustBalance) < 2350} // Disable button if dustBalance is less than 950
                            >
                                {parseFloat(dustBalance) < 2350 ? "ADD FUNDS" : "BUY"}
                            </button>
                        </div>
                        <div className="buy">
                            <img src={seventeen} className="coin-bag" alt="small bag of tokens"></img>
                            <p>4700 $DUST → 170 $TIT</p>
                            <button 
                                onClick={transferDust30} 
                                className="button"
                                disabled={parseFloat(dustBalance) < 4700} // Disable button if dustBalance is less than 950
                            >
                                {parseFloat(dustBalance) < 4700 ? "ADD FUNDS" : "BUY"}
                            </button>
                        </div>
                        <div className="buy">
                            <img src={thirtysix} className="coin-bag" alt="small bag of tokens"></img>
                            <p>9400 $DUST → 360 $TIT</p>
                            <button 
                                onClick={transferDust60} 
                                className="button"
                                disabled={parseFloat(dustBalance) < 9400} // Disable button if dustBalance is less than 950
                            >
                                {parseFloat(dustBalance) < 9400 ? "ADD FUNDS" : "BUY"}
                            </button>
                        </div>
                        <div className="buy">
                            <img src={ninetyfive} className="coin-bag" alt="small bag of tokens"></img>
                            <p>23600 $DUST → 950 $TIT</p>
                            <button 
                                onClick={transferDust150} 
                                className="button"
                                disabled={parseFloat(dustBalance) < 23600} // Disable button if dustBalance is less than 950
                            >
                                {parseFloat(dustBalance) < 23600 ? "ADD FUNDS" : "BUY"}
                            </button>
                        </div>
                        <div className="buy">
                            <img src={twenty} className="coin-bag" alt="small bag of tokens"></img>
                            <p>48000 $DUST → 2000 $TIT</p>
                            <button 
                                onClick={transferDust300} 
                                className="button"
                                disabled={parseFloat(dustBalance) < 48000} // Disable button if dustBalance is less than 950
                            >
                                {parseFloat(dustBalance) < 48000 ? "ADD FUNDS" : "BUY"}
                            </button>
                        </div>
                    </div>

                </div>
            )}

            <div class="section-divider"></div>
            <NFTDisplay />
        </div>
    );
}

export default Shop;