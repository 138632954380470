// src/assets/images.js

// Import all images
import img1 from './popup/1.png';
import img2 from './popup/2.png';
import img3 from './popup/3.png';
import img4 from './popup/4.png';
import img5 from './popup/5.png';
import img6 from './popup/6.png';
import img7 from './popup/7.png';
import img8 from './popup/8.png';
import img9 from './popup/9.png';
import img10 from './popup/10.png';
import img11 from './popup/11.png';
import img12 from './popup/12.png';
//import img13 from './popup/13.png';

import mof170 from './loadCourtiers/MarchionessOliviaFairchild170.png';
import mlp190 from './loadCourtiers/MarchionessLydiaPembroke190.png';
import mhr215 from './loadCourtiers/MarchionessHelenaRavensdale215.png';
import mfc190 from './loadCourtiers/MarchionessFionaCarrington190.png';
import lvs190 from './loadCourtiers/LadyVivienneSterling190.png';
import lrc170 from './loadCourtiers/LadyRosalindClifford170.png';
import lpt215 from './loadCourtiers/LadyPenelopeThornwood215.png';
import lew215 from './loadCourtiers/LadyEleanorWhitmore215.png';
import lcb215 from './loadCourtiers/LadyConstanceBlackwood215.png';
import dvc215 from './loadCourtiers/DameVictoriaChesterfield215.png';
import djw170 from './loadCourtiers/DameJosephineWycliffe170.png';
import dcs170 from './loadCourtiers/DameCharlotteSinclair170.png';
import dah230 from './loadCourtiers/DameAbigailHargrove230.png';
import cva230 from './loadCourtiers/CountessVictoriaAshwood230.png';
import cmc170 from './loadCourtiers/CountessMatildaCavendish170.png';
import cev170 from './loadCourtiers/CountessEleanordeVere170.png';
import ccg190 from './loadCourtiers/CountessCharlotteGreenwood190.png';
import cbh190 from './loadCourtiers/CountessBeatriceHarrowgate190.png';
import bsl170 from './loadCourtiers/BaronessSophiaLancaster170.png';
import blh190 from './loadCourtiers/BaronessLydiaHensley190.png';
import bcb215 from './loadCourtiers/BaronessCeciliaBeaumont215.png';

// Export all images as an object
export const images = {
    popupImages: [
        { src: img1, alt: "Evelyn - Master of Ceremonies", tits: "Perky", price: "8" },
        { src: img2, alt: "Eliza - Palace Steward", tits: "Ample", price: "12" },
        { src: img3, alt: "Amilia - Lord Chamberlain", tits: "Perky", price: "8" },
        { src: img4, alt: "Mabel - Master Chef", tits: "Perky", price: "10" },
        { src: img5, alt: "Sophia - Palace Librarian", tits: "Luscious", price: "12" },
        { src: img6, alt: "Olivia - Palace Treasurer", tits: "Luscious", price: "8" },
        { src: img7, alt: "Grace - Queen's Chambers", tits: "Ample", price: "10" },
        { src: img8, alt: "Aurora - Court Historian", tits: "Ample", price: "12" },
        { src: img9, alt: "Isabelle - Royal Stables", tits: "Perky", price: "10" },
        { src: img10, alt: "Natalie - Captain of the Guard", tits: "Perky", price: "8" },
        { src: img11, alt: "Lucy - Court Musician", tits: "Perky", price: "10" },
        { src: img12, alt: "Genevieve - Ambassador Liaison", tits: "Ample", price: "12" },
    ],
    courtierImages: [
        { src: mof170, alt: "Marchioness Olivia Fairchild", tits: "Luscious", price: "18" },
        { src: mlp190, alt: "Marchioness Lydia Pembroke", tits: "Perky", price: "20" },
        { src: mhr215, alt: "Marchioness Helena Ravensdale", tits: "Luscious", price: "23" },
        { src: mfc190, alt: "Marchioness Fiona Carrington", tits: "Perky", price: "20" },
        { src: lvs190, alt: "Lady Vivienne Sterling", tits: "Perky", price: "20" },
        { src: lrc170, alt: "Lady Rosalind Clifford", tits: "Ample", price: "18" },
        { src: lpt215, alt: "Lady Penelope Thornwood", tits: "Perky", price: "23" },
        { src: lew215, alt: "Lady Eleanor Whitmore", tits: "Perky", price: "23" },
        { src: lcb215, alt: "Lady Constance Blackwood", tits: "Luscious", price: "23" },
        { src: dvc215, alt: "Dame Victoria Chesterfield", tits: "Perky", price: "23" },
        { src: djw170, alt: "Dame Josephine Wycliffe", tits: "Ample", price: "18" },
        { src: dcs170, alt: "Dame Charlotte Sinclair", tits: "Ample", price: "18" },
        { src: dah230, alt: "Dame Abigail Hargrove", tits: "Ample", price: "25" },
        { src: cva230, alt: "Countess Victoria Ashwood", tits: "Luscious", price: "25" },
        { src: cmc170, alt: "Countess Matilda Cavendish", tits: "Ample", price: "18" },
        { src: cev170, alt: "Countess Eleanor de Vere", tits: "Ample", price: "18" },
        { src: ccg190, alt: "Countess Charlotte Greenwood", tits: "Ample", price: "20" },
        { src: cbh190, alt: "Countess Beatrice Harrowgate", tits: "Ample", price: "20" },
        { src: bsl170, alt: "Baroness Sophia Lancaster", tits: "Luscious", price: "18" },
        { src: blh190, alt: "Baroness Lydia Hensley", tits: "Luscious", price: "20" },
        { src: bcb215, alt: "Baroness Cecilia Beaumont", tits: "Luscious", price: "23" },
    ],
};
