import React, { useState, useEffect } from 'react';
import * as fcl from '@blocto/fcl';
import { getPortraitListingDetails } from '../../cadence/scripts/getPortraitListingDetails';
import { getPortraitDetails } from '../../cadence/scripts/getPortraitDetails';
import { purchasePortraitListing } from '../../cadence/transactions/purchasePortraitListing';
import './styles.css';

const NFTDisplay = () => {
    const [groupedNfts, setGroupedNfts] = useState([]);
    const [purchaseStatus, setPurchaseStatus] = useState({}); // Stores the purchase status for each NFT


    useEffect(() => {
        const fetchData = async () => {
            try {
                const listingsResult = await fcl.query({
                    cadence: getPortraitListingDetails,
                    args: (arg, t) => [arg("0x66b60643244a7738", t.Address)],
                });

                const detailsResult = await fcl.query({
                    cadence: getPortraitDetails,
                    args: (arg, t) => [arg("0x66b60643244a7738", t.Address)],
                });

                const grouped = groupNfts(listingsResult, detailsResult);
                setGroupedNfts(grouped);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const purchasePortrait = async (listingId, nftKey) => {
        setPurchaseStatus(prev => ({ ...prev, [nftKey]: 'processing' }));
        try {
            const txId = await fcl.mutate({
                cadence: purchasePortraitListing,
                args: (arg, t) => [
                    arg(listingId, t.UInt64),
                    arg("0x66b60643244a7738", t.Address)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
            console.log("Transaction ID:", txId);
            await fcl.tx(txId).onceSealed();
            setPurchaseStatus(prev => ({ ...prev, [nftKey]: 'completed' }));
        } catch (error) {
            console.error("Failed to purchase NFT:", error);
            setPurchaseStatus(prev => ({ ...prev, [nftKey]: 'error' }));
        }
    }

    const ProcessingButton = ({ nftKey }) => {
        const [dots, setDots] = useState('');
    
        useEffect(() => {
            const interval = setInterval(() => {
                setDots(d => (d.length < 3 ? d + '.' : ''));
            }, 500); // Change dot animation speed here
    
            return () => clearInterval(interval);
        }, []);
    
        return <button disabled>Processing{dots}</button>;
    };
    
    
    function groupNfts(listings, details) {
        const nftMap = new Map();
        const excludeNames = ["Lady Genevieve Montfort", "Lady Isabella Fairfax", "Baroness Arabella Forsythe", "Lady Amelia Wentworth"];
    
        listings.forEach(listing => {
            listing.nftIDs.forEach(id => {
                const detail = details.find(detail => detail.id === id);
                if (detail && !excludeNames.includes(detail.name)) {
                    const imageUrl = `https://indigo-wooden-crocodile-877.mypinata.cloud/ipfs/${detail.image}`;
                    const cid = detail.image;  // Assuming 'detail.image' contains the CID directly.
                    console.log(`CID: ${cid}`);  // Log the CID for each NFT image.
    
                    const key = `${imageUrl}-${detail.name}-${JSON.stringify(detail.traits)}`;
                    const data = nftMap.get(key) || {
                        ...detail,
                        image: imageUrl,
                        prices: [],
                        ids: [],
                        purchased: listing.purchased,  // Add purchased status
                        listingId: listing.listingId  // Save the listing ID associated with this NFT
                    };
                    if (!data.purchased) {  // Update only if not purchased
                        data.prices.push(listing.price);
                        data.ids.push(id);
                    }
                    nftMap.set(key, data);
                }
            });
        });
    
        return Array.from(nftMap.values());
    }

    return (
        <div className='container'>
            <h3>Purchase Courtiers</h3>
            <div className="nft-grid">
                {groupedNfts.map((nft, index) => {
                    const nftKey = `${nft.image}-${nft.name}-${JSON.stringify(nft.traits)}`;
                    return (
                        <div key={index} className="nft-tile">
                            <h4>{nft.name}</h4>
                            <img src={nft.image} alt={nft.name} />
                            <div className="traits-container">
                                <div className="trait">
                                    <strong>LOCATION</strong>
                                    <p>{nft.traits.Location}</p>
                                </div>
                                <div className="trait">
                                    <strong>ATTIRE</strong>
                                    <p>{nft.traits.Attire}</p>
                                </div>
                                <div className="trait">
                                    <strong>TITS</strong>
                                    <p>{nft.traits.Breasts}</p>
                                </div>
                            </div>
                            <p className="price">{Math.min(...nft.prices)} TIT$</p>
                            {nft.purchased ? (
                                <button disabled>Sold Out</button>
                            ) : purchaseStatus[nftKey] === 'processing' ? (
                                <ProcessingButton nftKey={nftKey} />
                            ) : (
                                <button onClick={() => purchasePortrait(nft.listingId, nftKey)}>Buy</button>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
    
    
};

export default NFTDisplay;
