export const getPortraitDetails = `

import TitPalacePortraits from 0x66b60643244a7738

// Script to retrieve all NFTs in a user's TitPalacePortraits collection
pub fun main(accountAddress: Address): [TitPalacePortraits.titPalacePortraitsMetadata] {
    // Get the public capability for the TitPalacePortraits collection
    let collectionRef = getAccount(accountAddress)
        .getCapability<&TitPalacePortraits.Collection{TitPalacePortraits.CollectionPublic}>(TitPalacePortraits.CollectionPublicPath)
        .borrow()
        ?? panic("Could not borrow reference to the collection")

    // Get all NFT IDs in the user's collection
    let nftIDs = collectionRef.getIDs()
    
    // Prepare an array to hold the metadata of each NFT
    var nftDetails: [TitPalacePortraits.titPalacePortraitsMetadata] = []

    // Loop through all NFT IDs and fetch the metadata
    for id in nftIDs {
        let nftRef = collectionRef.borrowTitPalacePortraits(id: id)
            ?? panic("Failed to borrow reference to NFT ID")

        // Create metadata object
        let metadata = TitPalacePortraits.titPalacePortraitsMetadata(
            id: nftRef.id,
            name: nftRef.name,
            description: nftRef.description,
            image: nftRef.image,
            traits: nftRef.traits
        )

        // Append the metadata to the results array
        nftDetails.append(metadata)
    }

    // Return the array of metadata
    return nftDetails
}


`