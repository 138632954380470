import React from 'react';
import SlotMachine from './slotmachine';
import "./parlor.css"
import * as fcl from '@blocto/fcl';
import ageVerificationBadge from '../../assets/photos/Badges/ageVerification.png'
import { useState, useEffect } from "react";
import { transferPalaceBadges } from '../../cadence/transactions/transferPalaceBadges';
import { getPalaceBadgesDetails } from '../../cadence/scripts/getPalaceBadgesDetails';
import { getPalaceBadgesIDs } from '../../cadence/scripts/getPalaceBadgesIDs';
import { initPalaceBadges } from '../../cadence/transactions/initPalaceBadges';
import { getPortraitDetails } from '../../cadence/scripts/getPortraitDetails';


export default function Parlor  () {
    const [user, setUser] = useState({ loggedIn: false, addr: null });
    const [portraits, setPortraits] = useState([]);
    const [palaceBadgesIDs, setPalaceBadgesIDs] = useState([]);
    const [isInitializing, setIsInitializing] = useState(false);
    const [palaceBadges, setPalaceBadges] = useState([]);
    const [ageVerified, setAgeVerified] = useState(false);
    const [verifiedBadge, setVerifiedBadge] = useState(null); 
    

    useEffect(() => {
        const unsubscribe = fcl.currentUser().subscribe(user => {
            setUser({ loggedIn: user.loggedIn, addr: user.addr });
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user.addr) {
            fetchBadgeDetails();
            fetchBadgeIDs();
        }
    }, [user.addr]);

    const fetchPortraits = async () => {
        try {
            const portraitsResult = await fcl.query({
                cadence: getPortraitDetails,
                args: (arg, t) => [arg(user.addr, t.Address)],
            });
            setPortraits(portraitsResult);
        } catch (error) {
            console.error('Error fetching portrait details:', error);
        }
    };

    useEffect(() => {
        if (user.addr) {
            fetchPortraits();
        }
    }, [user.addr]);  // Make sure this useEffect is placed correctly in your component and is active.
    
    

    async function fetchBadgeDetails() {
        try {
            const details = await fcl.query({
                cadence: getPalaceBadgesDetails,
                args: (arg, t) => [arg("0x66b60643244a7738", t.Address)],
            });
            console.log("Badge Details: ", details);
            setPalaceBadges(details);
            checkAgeVerification(details);
        } catch (error) {
            console.error("Error fetching Palace Badge details:", error);
        }
    }
    
    function checkAgeVerification(details) {
        const verifiedBadge = details.find(badge => badge.description === user.addr);
        if (verifiedBadge) {
            setAgeVerified(true);
            setVerifiedBadge(verifiedBadge); // Store the verified badge details
        }
    }
    
    async function fetchBadgeIDs() {
        try {
            const details = await fcl.query({
                cadence: getPalaceBadgesIDs,
                args: (arg, t) => [arg("0x66b60643244a7738", t.Address)],
            });
            console.log("IDs: ", details);
            setPalaceBadgesIDs(details);
        } catch (error) {
            console.error("Error fetching Palace Badge IDs:", error);
        }
    }


    return (
        <div>
            <div className='top-container'>
                <h1>Parlor</h1>
            </div>
            <div className='courtiers-section'>
                <h2>Courtiers</h2>
                <div className="portrait-grid">
                    {portraits.map((portrait, index) => (
                        <div key={index} className="portrait-tile">
                            <img src={`https://indigo-wooden-crocodile-877.mypinata.cloud/ipfs/${portrait.image}`} alt={portrait.name} style={{ width: '100%', borderRadius: '4px' }} />
                            <div className="portrait-details">
                                <h3>{portrait.name}</h3> {/* This now spans all columns */}
                                <div className="trait">
                                    <span className="trait-name">Location</span>
                                    <span className="trait-value">{portrait.traits.Location}</span>
                                </div>
                                <div className="trait">
                                    <span className="trait-name">Attire</span>
                                    <span className="trait-value">{portrait.traits.Attire}</span>
                                </div>
                                <div className="trait">
                                    <span className="trait-name">Breasts</span>
                                    <span className="trait-value">{portrait.traits.Breasts}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>



            <div className='top-container'>
                <h2>Permissions</h2>
            </div>
            {ageVerified && verifiedBadge && (
                <div className="badge-gallery">
                    <div className="badge-tile">
                        <img src={ageVerificationBadge} alt="Verified Badge" className="badge-image" />
                        <div className="badge-details">
                            <h3>{verifiedBadge.name}</h3>
                            <div className="traits">
                                {Object.entries(verifiedBadge.traits).map(([trait, value], idx) => (
                                    <div key={idx} className="trait">
                                        <span className="trait-name">{trait}:</span>
                                        <span className="trait-value">{value}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="badge-id">ID: {verifiedBadge.id}</div>
                        </div>
                    </div>
                </div>
            )}
            


            {/*
            <SlotMachine /> 
            */}
        </div>
    );
};

