export const initTit=`
//Mainnet

import FungibleToken from 0xf233dcee88fe0abe
import TitToken from 0x66b60643244a7738
import TitPalacePortraits from 0x66b60643244a7738
import NonFungibleToken from 0x1d7e57aa55817448
import MetadataViews from 0x1d7e57aa55817448

transaction() {

let address: Address

    prepare(account: AuthAccount){

        self.address = account.address

        if account.borrow<&TitToken.Vault>(from: TitToken.VaultStoragePath) == nil {
            let vault <- TitToken.createEmptyVault()
            account.save<@TitToken.Vault>(<-vault, to: TitToken.VaultStoragePath)
        }

        let titTokenCap = account.getCapability<&TitToken.Vault{FungibleToken.Receiver}>(TitToken.VaultReceiverPath)
        if(!titTokenCap.check()) {
            account.unlink(TitToken.VaultReceiverPath)
            // Create a public Receiver capability to the Vault
            account.link<&TitToken.Vault{FungibleToken.Receiver, FungibleToken.Balance}>(TitToken.VaultReceiverPath, target: TitToken.VaultStoragePath)
        }

        let titTokenCapBalance = account.getCapability<&TitToken.Vault{FungibleToken.Balance}>(TitToken.VaultBalancePath)
        if(!titTokenCapBalance.check()) {
            account.unlink(TitToken.VaultBalancePath)
            // Create a public Receiver capability to the Vault
            account.link<&TitToken.Vault{FungibleToken.Balance}>(TitToken.VaultBalancePath, target: TitToken.VaultStoragePath)
        }

        let genCap = account.getCapability<&{NonFungibleToken.CollectionPublic, TitPalacePortraits.CollectionPublic}>(TitPalacePortraits.CollectionPublicPath)

        if !genCap.check(){
            account.save(<- TitPalacePortraits.createEmptyCollection(), to: TitPalacePortraits.CollectionStoragePath)
            account.link<&TitPalacePortraits.Collection{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, TitPalacePortraits.CollectionPublic, MetadataViews.ResolverCollection}>(TitPalacePortraits.CollectionPublicPath, target: TitPalacePortraits.CollectionStoragePath)
        }

    }
}
`