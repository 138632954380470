import React, { useState, useEffect, useRef } from "react";
import { initTit } from '../../cadence/initTit';
import { transferFlow } from '../../cadence/transferFlow';
import * as fcl from '@blocto/fcl';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import './homepage.css'
import StripeCheckout from "react-stripe-checkout";
import { images } from '../../assets/images';

import banner from '../../assets/photos/backgrounds/europeNew.png';
import icon1 from '../../assets/photos/tile1new.png';
import icon2 from '../../assets/photos/tile2new.png';
import icon3 from '../../assets/photos/tile3new.png';
import tile1 from '../../assets/photos/tiles/tile1.png';
import tile2 from '../../assets/photos/tiles/tile2.png';
import tileQuote1 from '../../assets/photos/tiles/tileQuote3.png';
import tileBig1 from '../../assets/photos/tiles/tileBig2.png';
import tileRight3 from '../../assets/photos/tiles/tileRight1.png';
import tile3 from '../../assets/photos/tiles/tile3.png';
import tile4 from '../../assets/photos/tiles/tile4.png';
import tile5 from '../../assets/photos/tiles/tile5.png';
import tile6 from '../../assets/photos/tiles/tile6.png';
import tile7 from '../../assets/photos/tiles/tile7.png';
import tile8 from '../../assets/photos/tiles/tile8.png';

import book1 from '../../assets/photos/tiles/book1.png';
import book2 from '../../assets/photos/tiles/book2.png';
import book3 from '../../assets/photos/tiles/book3.png';
import book4 from '../../assets/photos/tiles/book4.png';

function HomePage() {
    const [user, setUser] = useState({ loggedIn: null });  
    const [userAddress, setUserAddress] = useState(null);
    const [selectedType, setSelectedType] = useState('pages');
    const [displayedImages, setDisplayedImages] = useState([]);  // Rename to 'displayedImages'
    const [leftTileIndex, setLeftTileIndex] = useState(0);
    const [rightTileIndex, setRightTileIndex] = useState(1);

    const [product, setProduct] = useState({})

    const storyRowRef = useRef(null);
    const imageRowRef = useRef(null);

    const scrollToStoryRow = () => {
        storyRowRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    
    const scrollToImageRow = () => {
        imageRowRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRightTileIndex(prevIndex => (prevIndex + 2) % tiles.length);
            setTimeout(() => {
                setLeftTileIndex(prevIndex => (prevIndex + 2) % tiles.length);
            }, 5000);
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const tiles = [tile1, tile2, tile3, tile4, tile5, tile6, tile7, tile8];

    useEffect(() => {
        fcl.currentUser.subscribe((currentUser) => {
            setUser(currentUser);
            setUserAddress(currentUser.addr);
        });
    }, []); 

    const initTitToken = async () => {
        try {
            const transactionId = await fcl.mutate({
                cadence: initTit,
                args: (arg, t) => [],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
            
            const transaction = await fcl.tx(transactionId).onceSealed();
            console.log(transaction);
            
            if (transaction.status === 4) {
                toast.success("Successfully initialized TIT Token!");
            } else {
                toast.error("Transaction failed: " + transaction.errorMessage);
            }
        } catch (error) {
            console.error("Transaction failed", error);
            toast.error("Transaction failed: " + error.message);
        }
    };

    useEffect(() => {
        function loadImages(type) {
            if (type === 'pages') {
                setDisplayedImages(images.popupImages); // Now 'images' refers to the imported object
            } else if (type === 'courtiers') {
                setDisplayedImages(images.courtierImages); // Now 'images' refers to the imported object
            }
        }
    
        loadImages(selectedType);
    }, [selectedType]);

    return (
        <div className="homepage">
            <div className="banner-image">
                <img src={tiles[leftTileIndex]} alt="Left Tile" className="tile" />
                <img src={tileQuote1} alt="Tile Quote 1" className="tile" />
                <img src={tiles[rightTileIndex]} alt="Right Tile" className="tile" />
                <div className="tile-big-container">
                    <img src={tileBig1} alt="Tile Big 1" className="tile tile-big" />
                    <div className="overlay-content">
                        <h2>SEX & SECRETS</h2>
                        <p>Unveil the hidden allure of the Tit Palace</p>
                        <div className="button-row">
                        <button className="overlay-button" onClick={scrollToStoryRow}>Enamor</button>
                        <button className="overlay-button" onClick={scrollToImageRow}>Revel</button>
                        </div>
                    </div>
                </div>
                <img src={tileRight3} alt="Tile 3" className="tile tile-small" />
            </div>
            <div className="special-offer">
                <h4>Exclusive Offer!</h4>
                <h2>Sign up now to receive a FREE NFT from the Page collection!</h2>
                <p>Delivered within 24 hours</p>
            </div>
            <div className="type-selector">
                <button onClick={() => setSelectedType('pages')} className="type-button">Pages</button>
                <button onClick={() => setSelectedType('courtiers')} className="type-button">Courtiers</button>
            </div>
            <div className="image-row" ref={imageRowRef}>
                {displayedImages.map((image, index) => (
                    <div key={index} className="image-tile">
                        {image.price === "18" ? (
                            <a href="https://buy.stripe.com/5kAbJV7dP5Eq7f2fYY" target="_blank" rel="noopener noreferrer">
                                <img src={image.src} alt={image.alt} />
                            </a>
                        ) : image.price === "20" ? (
                            <a href="https://buy.stripe.com/28oaFRgOpeaW0QEfYZ" target="_blank" rel="noopener noreferrer">
                                <img src={image.src} alt={image.alt} />
                            </a>
                        ) : image.price === "23" ? (
                            <a href="https://buy.stripe.com/eVa5lx9lXgj41UI146" target="_blank" rel="noopener noreferrer">
                                <img src={image.src} alt={image.alt} />
                            </a>
                        ) : image.price === "25" ? (
                            <a href="https://buy.stripe.com/28o01dbu53wieHu8wz" target="_blank" rel="noopener noreferrer">
                                <img src={image.src} alt={image.alt} />
                            </a>
                        ) : image.price === "8" ? (
                            <a href="https://buy.stripe.com/dR67tFcy9d6SeHu5ko" target="_blank" rel="noopener noreferrer">
                                <img src={image.src} alt={image.alt} />
                            </a>
                        ) : image.price === "10" ? (
                            <a href="https://buy.stripe.com/28oaFR41D2se9naaEJ" target="_blank" rel="noopener noreferrer">
                                <img src={image.src} alt={image.alt} />
                            </a>
                        ) : image.price === "12" ? (
                            <a href="https://buy.stripe.com/eVadS355H6IugPCdQW" target="_blank" rel="noopener noreferrer">
                                <img src={image.src} alt={image.alt} />
                            </a>
                        ) : (
                            <img src={image.src} alt={image.alt} />
                        )}
                        <div className="price-tag">$ {image.price}</div>
                        <h5>{image.alt}</h5>
                        <p>Tits: {image.tits}</p>
                    </div>
                ))}
            </div>
            <div className="story-row" ref={storyRowRef}>
                <div className="book-tile">
                    <img src={book1} alt="book cover 1" />
                    <h5>Forbidden Whispers</h5>
                    <p>Housekeeper Eliza and Countess Eleanor share a charged moment of admiration and unspoken desires within the luxurious halls of Tit Palace.</p>
                    <a>Coming Soon</a>
                </div>
                <div className="book-tile">
                    <img src={book2} alt="book cover 2" />
                    <h5>Afternoon Encounter</h5>
                    <p>Chef Mabel admires Dame Victoria's grace, leading to an unexpected, ravenous scamper in Victoria's private quarters.</p>
                    <Link to="/afternoon-encounter">Read Now</Link>
                </div>
                <div className="book-tile">
                    <img src={book3} alt="book cover 3" />
                    <h5>Palace Temptations</h5>
                    <p>Isabelle battles duty and forbidden desire for Lady Eleanor within Tit Palace's opulent walls.</p>
                    <a>Coming Soon</a>
                </div>
                <div className="book-tile">
                   <img src={book4} alt="book cover 4" />
                   <h5>Emerald Seduction</h5>
                   <p>Countess Charlotte Greenwood's garden encounter with the enigmatic Lord Sebastian Thornwood sparks intrigue and desire.</p>
                   <a>Coming Soon</a>
                </div>
            </div>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} />
        </div>
    );
}

export default HomePage;
